<!-- 单据汇总 -->
<template>
    <div class="BillSummary">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">单据汇总</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <el-form ref="form" :model="form"  id="form-1" class="" inline label-width="150px">
           <el-form-item label="开班入学">
               <el-input  placeholder="" :value="form.openClass" disabled></el-input>
           </el-form-item>
           <el-form-item label="开班入学金额">
               <el-input  placeholder="" :value="form.openMoney" disabled></el-input>
           </el-form-item>
           <el-form-item label="开班入学定金">
               <el-input  placeholder="" :value="form.openDeposit" disabled></el-input>
           </el-form-item>
           <el-form-item label="插班入学">
               <el-input  placeholder="" :value="form.shiftClass" disabled></el-input>
           </el-form-item>
           <el-form-item label="插班金额">
               <el-input  placeholder="" :value="form.shiftMoney" disabled></el-input>
           </el-form-item>
           <el-form-item label="插班入学定金">
               <el-input  placeholder="" :value="form.shiftDeposit" disabled></el-input>
           </el-form-item>
           <el-form-item label="结业1">
               <el-input  placeholder="" :value="form.graduation_1" disabled></el-input>
           </el-form-item>
           <el-form-item label="结业1金额">
               <el-input  placeholder="" :value="form.graduation_1_Money" disabled></el-input>
           </el-form-item>
           <el-form-item label="现金金额">
               <el-input  placeholder="" :value="form.cash" disabled></el-input>
           </el-form-item>
           <el-form-item label="结业2">
               <el-input  placeholder="" :value="form.graduation_2" disabled></el-input>
           </el-form-item>
           <el-form-item label="结业2金额">
               <el-input  placeholder="" :value="form.graduation_2_Money" disabled></el-input>
           </el-form-item>
           <el-form-item label="银行卡转账金额">
               <el-input  placeholder="" :value="form.bankCard" disabled></el-input>
           </el-form-item>
           <el-form-item label="退学">
               <el-input  placeholder="" :value="form.dropOut" disabled></el-input>
           </el-form-item>
           <el-form-item label="退学金额">
               <el-input  placeholder="" :value="form.dropOutMoney" disabled></el-input>
           </el-form-item>
           <el-form-item label="借记卡金额">
               <el-input  placeholder="" :value="form.debitCard" disabled></el-input>
           </el-form-item>
           <el-form-item label="信用卡金额">
               <el-input  placeholder="" :value="form.creditCard" disabled></el-input>
           </el-form-item>
           <el-form-item label="移动支付金额">
               <el-input  placeholder="" :value="form.mobilePay" disabled></el-input>
           </el-form-item>
           <el-form-item label="优惠前应收金额">
               <el-input  placeholder="" :value="form.B_discount" disabled></el-input>
           </el-form-item>
           <el-form-item label="优惠后应收金额">
               <el-input  placeholder="" :value="form.A_discount" disabled></el-input>
           </el-form-item>
           <el-form-item label="优惠金额">
               <el-input  placeholder="" :value="form.Discount" disabled></el-input>
           </el-form-item>
           <el-form-item label="实收金额">
               <el-input  placeholder="" :value="form.actual" disabled></el-input>
           </el-form-item>
           <el-form-item label="记录数">
               <el-input  placeholder="" :value="form.recordNum" disabled></el-input>
           </el-form-item>
        </el-form>    
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   openClass:"",//开班入学
                   openMoney:"",//开班入学金额
                   openDeposit:"",//开班入学定金
                   shiftClass:"",//插班入学
                   shiftMoney:"",//插班金额
                   shiftDeposit:"",//插班入学定金
                   graduation_1:"",//结业1
                   graduation_1_Money:"",//结业1金额
                   cash:"",//现金金额
                   graduation_2:"",//结业2
                   graduation_2_Money:"",//结业2金额
                   bankCard:"",//银行卡转账金额
                   dropOut:"",//退学
                   dropOutMoney:"",//退学金额
                   debitCard:"",//借记卡金额
                   creditCard:"",//信用卡金额
                   mobilePay:"",//移动支付金额
                   B_discount:"",//优惠前应收金额
                   A_discount:"",//优惠后应收金额
                   Discount:"",//优惠金额
                   actual:"",//实收金额
                   recordNum:""//记录数
                }
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.push({
                    path: "trainNewborn"
                })
            },
        }
    }
</script>

<style scoped="scoped">
    .BillSummary{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
        
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
</style>
